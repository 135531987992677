import Header from './components/Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Banner from './components/Banner';
import style from  "../src/css/style.css";
import Feature from './components/Feature';
import About from './components/About';
import Why from './components/Why';
import How from './components/How';
import Pricing from './components/Pricing';
import Login from './components/Login';
import Benifits from './components/Benifits';
import Faq from './components/Faq';
import Footer from './components/Footer';
import Dashboard from './components/Dashboard';
import Invoices from './components/Invoices';
import InvoiceDetails from "./components/InvoiceDetails";
import Contact from './components/Contact';
function App() {
  return (
    <div className="App">
       <Router>
      <div className="App">
      
        <Routes>
          <Route path="/" element={<>
            <Header />
            <Banner />
            <About />
            <Feature />
            <Why />
            <How />
            <Pricing />
            <Benifits/>
            <Contact/>
            <ToastContainer position="top-right" autoClose={5000} />
            {/* <Faq /> */}
            <Footer />
          </>} />
          {/* <Route path="/Register" element={<Register />} /> */}
          <Route path='/Dashboard' element={<Dashboard/>} />
          <Route path ='/Header' element={<Header/>}/>
          <Route path='/Login' element={<Login/>}/>
          <Route path='/Invoices' element={<Invoices/>}/>
          <Route path="/invoiceDetails/:id" element={<InvoiceDetails />} />
        </Routes>
      </div>
    </Router>
    </div>
  );
}

export default App;
