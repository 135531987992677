import React from 'react';
import about_img from "../assets/images/about_us.webp";

const About = () => {
  return (
    <div>
      <section className="about-us-main" id="about" style={{ padding: "60px 0" }}>
        <div className="container">
          <div className="heading-lines text-center mb-5">
            <h2 className="display-4"><span>About Us</span></h2>
          </div>

          <div className="row align-items-center">
            {/* Text Section */}
            <div className="col-md-6 mb mb-md-0">
              <div className="about-us-text bg-gradient  rounded shadow-lg">
                <h3 className="mb-4">Who We Are</h3>
                <p className="lead">
                  At Mails R Us, we’re passionate about revolutionizing the way you stay connected.
                </p>
                <p>
                  Our platform is built with efficiency, security, and user-friendliness in mind, so you can focus on what truly matters — your communication. Whether it's personal or professional, we’ve got the tools you need to stay organized.
                </p>
                <blockquote className="blockquote mt-4">
                  <p>"Empowering communication, one email at a time."</p>
                </blockquote>

                <a href="#" className="btn btn-primary">Learn More</a>
              </div>
            </div>

            {/* Image Section */}
            <div className="col-md-6">
              <div className="about-img-wrapper">
                <img src={about_img} className="img-fluid rounded-lg shadow" alt="About Us" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
