import React from 'react';
import img1 from "../assets/images/plans.avif";
import img2 from "../assets/images/securepayment.png";
import img3 from "../assets/images/customer.avif";

const How = () => {
  return (
    <div>
      <section className="how-to-use-main" id='how'>
        <div className="container">
          <div className="text-center mb">
            <h2 className="section-title">
              How to Use
              <div className="underline-box">
                <span className="underline"></span>
              </div>
            </h2>
          </div>

          {/* First Row */}
          <div className="row how-use-row align-items-center mb-1">
            <div className="col-md-6 order-md-1 order-2">
              <div className="how-content1">
                <h5>Select the Ideal Plan for You</h5>
                <p>Browse through our variety of customized plans, crafted to suit everyone from individual users to large organizations. Discover the unique features of each plan and choose the one that best fits your email needs and storage preferences.</p>
              </div>
            </div>
            <div className="col-md-6 text-center order-md-2 order-1">
              <img src={img1} className="img-fluid how-img" alt="Select Plan"/>
            </div>
          </div>

          {/* Second Row */}
          <div className="row how-use-row align-items-center mb-1">
            <div className="col-md-6 text-center">
              <img src={img2} className="img-fluid how-img"  alt="Make Payment"/>
            </div>
            <div className="col-md-6">
              <div className="how-content1">
                <h5>Fast and Secure Payment</h5>
                <p>Complete your payment quickly and securely with our simple, user-friendly checkout process. We offer multiple payment options to ensure a seamless experience. Once your payment is confirmed, you’ll gain instant access to all the features of MailsRUs.</p>
              </div>
            </div>
          </div>

          {/* Third Row */}
          <div className="row how-use-row align-items-center">
            <div className="col-md-6 order-md-1 order-2">
              <div className="how-content1">
                <h5>Unlock All the Incredible Features</h5>
                <p>Our dedicated customer support team is always available to assist you with any questions or concerns. Feel free to contact us for guidance and enjoy a smooth experience with MailsRUs.</p>
              </div>
            </div>
            <div className="col-md-6 text-center order-md-2 order-1">
              <img src={img3}  className="img-fluid how-img"   alt="Unlock Features"/>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default How;
