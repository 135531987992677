import React from 'react';
import img1 from "../assets/images/multiple-account.png";
import img2 from "../assets/images/read-email.png";
import img3 from "../assets/images/undo-mail.png";
import img4 from "../assets/images/notification(2).png";
import img5 from "../assets/images/domain.png";
import img6 from "../assets/images/management.png";

const Benifits = () => {
  return (
    <div>
      <section className="benifits-main">
        <div className="container">
          <div className="heading-lines text-center">
            <h2>
              <span>Premium Plan Offerings</span>
            
            </h2>
          </div>
          <div className="row benifit-card-main">
            {/* First Row - Staggered layout */}
            <div className="col-xs-12 col-sm-6 col-md-4 mt-5">
              <div className="gx-3 benifit-card card-hover text-center shadow-sm">
                <div className="icon-circle">
                  <img src={img1} className="img-fluid" alt="" />
                </div>
                <p>Custom Email Aliases: Create and manage multiple email addresses connected to one account.</p>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-4 mt-5">
              <div className="gx-3 benifit-card card-hover text-center shadow-sm">
                <div className="icon-circle">
                  <img src={img2} className="img-fluid" alt="" />
                </div>
                <p>AI-Powered Smart Replies: Instant, AI-generated response suggestions for faster replies.</p>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4 mt-5">
              <div className="gx-3 benifit-card card-hover text-center shadow-sm">
                <div className="icon-circle">
                  <img src={img3} className="img-fluid" alt="" />
                </div>
                <p>Email Recall: Undo sending an email within a short window after it’s sent.</p>
              </div>
            </div>

            {/* Second Row */}
            <div className="col-xs-12 col-sm-12 col-md-4 mt-5">
              <div className="gx-3 benifit-card card-hover text-center shadow-sm">
                <div className="icon-circle">
                  <img src={img4} className="img-fluid" alt="" />
                </div>
                <p>Custom Alert Notifications: Tailor your notifications for specific contacts or categories.</p>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-4 mt-5">
              <div className="gx-3 benifit-card card-hover text-center shadow-sm">
                <div className="icon-circle">
                  <img src={img5} className="img-fluid" alt="" />
                </div>
                <p>Personalized Domain Integration: Use your own custom email domain for a professional look.</p>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4 mt-5">
              <div className="gx-3 benifit-card card-hover text-center shadow-sm">
                <div className="icon-circle">
                  <img src={img6} className="img-fluid" alt="" />
                </div>
                <p>Integrated Task Organizer: Manage tasks and to-do items directly within your email interface.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Benifits;
