import React from 'react';
import banner_img from "../assets/images/banner_img1.jpg";
import style from "../css/style.css";
const Banner = () => {
  return (
    <div>
      <section className="banner-main">
        <div className="container banner">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-5 banner-text">
                    <h1>Get the Most Out of <br/> Your Emails</h1>
                    <p>Mails R Us is your comprehensive online webmail solution, accessible from any device with an internet connection. It enables you to send, receive, and organize emails with ease, while providing ample storage, robust security features such as spam filtering and two-factor authentication, and smooth integration with tools like calendars and cloud storage. Effortlessly manage your communication, stay protected, and stay connected—anytime, anywhere.</p>
                    <a href="#pricing" className="btn btn-primary">get your mail</a>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-7 banner-img">
                    <img src={banner_img} className="img-fluid" alt=""/>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default Banner;
