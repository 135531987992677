import React from 'react';
import Logo from "../assets/images/Main_rus.png";
import In from "../assets/images/instagram.png";
import Fb from "../assets/images/facebook.png";
import Tw from "../assets/images/twitter.png";
import Lk from "../assets/images/linkdin.png";
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div>
         <footer className="footer-main">
        <div className="container footer">
            <img src={Logo} style={{height:'70px', width:''}} alt=""/>
 <p style={{color:'#41566a', fontSize:'18px',fontFamily:'Libre Baskerville' }}>Welcome to Mails R Us, where we’re revolutionizing the way you experience email. Our platform gives you the tools to turn your everyday email interactions into a profitable resource, unlocking new opportunities for efficiency. </p>
    <div className="footer-basic">
        <footer>
     
            <ul className="footer-link">
                <li className="nav-item">
                    <a className="nav-link" aria-current="page" href="#home">Home</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#about">About Us</a>
                </li>
                 
                <li className="nav-item">
                    <a className="nav-link" href="#how">How To Use</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#features">Features</a>
                </li>
             
                <li className="nav-item">
                    <a className="nav-link" href="#pricing">Pricing</a>
                </li>
                {/* <li className="nav-item">
                    <a className="nav-link" href="#faq">FAQ'S</a>
                </li> */}
            </ul>
      
            {/* <div className="footer-icons">
                <img src={In} className="img-fluid" alt=""/>
                <img src={Fb} className="img-fluid" alt=""/>
                <img src={Tw} className="img-fluid" alt=""/>
                <img src={Lk} className="img-fluid" alt=""/>
            </div> */}
       
            </footer>
            </div>
            </div>
        <p className="copyright text-center">© 2024 Copyright:All rights reserved.</p>
    </footer>
    </div>
  )
}

export default Footer;
