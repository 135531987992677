import React from 'react';
import img from "../assets/images/why.avif";
import img1 from "../assets/images/choose-1.png";
import img2 from "../assets/images/choose-2.png";
import img3 from "../assets/images/choose-3.png";
import img4 from "../assets/images/choose-4.png";

const Why = () => {
  return (
    <div>
         <section className="choose-us-main" id='why'>
        <div className="container">
            <div className="heading-lines text-center">
                <h2><span>Why Choose Us <div className="lines-box">
                        
                        </div></span></h2>
            </div>
            <div className="row choose-us">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
  <div className="choose-us-card">
    <h5 className='why-heading'>Inclusive Accessibility Options</h5>
    <p>At Mails R Us, we are committed to fostering an inclusive digital space for all users. Our advanced accessibility features are specifically designed to assist users with disabilities, ensuring that everyone can effortlessly communicate, collaborate, and stay connected.</p>
  </div>
  <div className="choose-us-card">
  <h5 className='why-heading'>Customizable Email Signatures</h5>
    <p>With Mails R Us, you can create and manage multiple email signatures for various contexts, projects, or recipients. Maintain consistent branding in every email with personalized signatures that represent your professional identity.</p>
  </div>
  <div className="choose-us-card">
  <h5 className='why-heading'>Out-of-Office Auto-Response</h5>
    <p>With Mails R Us Vacation Responders, you can stay professionally connected even when you're away. Whether on vacation, out of the office, or unavailable, our automatic replies ensure your contacts are always informed.</p>
  </div>
  <div className="choose-us-card">
  <h5 className='why-heading'>Unlimited Attachment Support</h5>
    <p>With Mails R Us cloud storage, you can upload and share files of any size without worrying about attachment limitations. Enjoy virtually limitless capacity for all your large file-sharing needs.</p>
  </div>
</div>



<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 choose-us-img">
  <div className="choose-us-img-overlay">
    <img src={img} className="img-fluid" alt="" />
  </div>
</div>

            </div>
        </div>
    </section>
    </div>
  )
}

export default Why;
