import React from 'react';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import AssistantIcon from '@mui/icons-material/Assistant';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import BackupIcon from '@mui/icons-material/Backup';

const Feature = () => {
  return (
    <div>
      <section className="features-main" id="features">
        <div className="container">
          <div className="heading-lines text-center">
            <h2 className='feature-heading'>
              <span>
                Features
               
              </span>
            </h2>
          </div>

          <div className="row features-card-main">
            <div className="col-xs-12 col-md-4 mt-5">
              <div className="card feature-card">
                <div className="card-icon">
                  <DarkModeIcon style={{ color: '#26658c', fontSize: '2.5rem' }} />
                </div>
                <div className="card-body">
                  <h5 className='card-heading'>Boundless Storage</h5>
                  <p className='card-para'>
                    With Mails R Us, Our unlimited storage allows you to keep all your vital emails without ever worrying about running out of space.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-4 mt-5">
              <div className="card feature-card">
                <div className="card-icon">
                  <DomainVerificationIcon style={{ color: '#26658c', fontSize: '2.5rem' }} />
                </div>
                <div className="card-body">
                  <h5 className='card-heading'>Personalized Domain</h5>
                  <p className='card-para'>
                    Enhance your professional image with a custom email address that aligns perfectly with your brand.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-4 mt-5">
              <div className="card feature-card">
                <div className="card-icon">
                  <AssistantIcon style={{ color: '#26658c', fontSize: '2.5rem' }} />
                </div>
                <div className="card-body">
                  <h5 className='card-heading'>Smart AI-Powered Reply</h5>
                  <p className='card-para'>
                    Boost your efficiency with AI-generated quick responses, helping you streamline email communication.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-4 mt-5">
              <div className="card feature-card">
                <div className="card-icon">
                  <AutoFixNormalIcon style={{ color: '#26658c', fontSize: '2.5rem' }} />
                </div>
                <div className="card-body">
                  <h5 className='card-heading'>Advanced Management Tools</h5>
                  <p className='card-para'>
                    Stay organized with our powerful email management tools. Use filters, categories to ensure you never miss an message.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-4 mt-5">
              <div className="card feature-card">
                <div className="card-icon">
                  <BackupIcon style={{ color: '#26658c', fontSize: '2.5rem' }} />
                </div>
                <div className="card-body">
                  <h5 className='card-heading'>Cloud Backup Integration</h5>
                  <p className='card-para'>
                    Your emails are safely stored in the cloud, ensuring you never lose important data. Enjoy our reliable backup integration.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-4 mt-5">
              <div className="card feature-card">
                <div className="card-icon">
                  <GTranslateIcon style={{ color: '#26658c', fontSize: '2.5rem' }} />
                </div>
                <div className="card-body">
                  <h5 className='card-heading'>Multi-Language Compatibility</h5>
                  <p className='card-para'>
                    Easily communicate across languages with our full support for sending and receiving emails in multiple languages.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Feature;
