import React from 'react';

const Contact = () => {
  const styles = {
    contactSection: {
      backgroundColor: '#D0E8FF',
      padding: '20px',
      borderTop: '1px solid #ddd',
      textAlign: 'center',
      marginTop:"50px"
    },
    cont: {
      fontSize: '16px',
      color: '#41566a',
      marginBottom: '10px'
    },
    link: {
      color: '#00658f',
      textDecoration: 'none',
      fontWeight: 'bold'
    },
    linkHover: {
      textDecoration: 'underline'
    },
    ul: {
      listStyleType: 'none',
      padding: '0',
      textAlign: 'left',
      display: 'inline-block'
    },
    li: {
      fontSize: '14px',
      color: '#1c4762',
      marginBottom: '5px'
    }
  };

  return (
    <>
  <div style={styles.contactSection}>
  <p style={styles.cont}>
    Have questions about your webmail service? Need assistance with any issues? Reach out to us at <a href="mailto:support@mailsrus.org" style={styles.link}>support@mailsrus.org</a>. We're here to help!
  </p>
  {/* <p style={styles.cont}>
    Our team can assist you with:
    <ul style={styles.ul}>
      <li style={styles.li}>Setting up and managing your email accounts</li>
      <li style={styles.li}>Troubleshooting email delivery and spam filtering issues</li>
      <li style={styles.li}>Understanding and managing your subscription and billing</li>
      <li style={styles.li}>Providing feedback or suggestions to improve your experience</li>
      <li style={styles.li}>Any other questions or concerns you may have</li>
    </ul>
  </p> */}
</div>

    </>
  );
};

export default Contact;
